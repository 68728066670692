import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { initialVuexStore } from '../assets/javascripts/setting'

initialVuexStore.plugins = [
    createPersistedState({
        storage: window.localStorage
    })
]
export default createStore(initialVuexStore)
